const jquery = require("jquery");
window.$ = window.jQuery = jquery;

require('popper.js');
require('bootstrap');
import 'bootstrap/dist/css/bootstrap.min.css';

// https://bootswatch.com/sandstone/
// import './scss/sandstone.bootstrap.min.css';
// ---------------------------------

// const katex = require("katex");
// import 'katex/dist/katex.min.css';
// const autokatex = require('katex/dist/contrib/auto-render.js');

// import renderMathInElement from "https://cdn.jsdelivr.net/npm/katex@0.11.1/dist/contrib/auto-render.mjs";


// import renderMathInElement from "katex/dist/contrib/auto-render";


import './scss/main.scss';


document.addEventListener("DOMContentLoaded", function() {


    $("#contact").submit(function(e) {
        e.preventDefault();
        console.log($.param({ a: 2, b: 3 }));
        let $form = $(this);
        console.log('FORM', $form.serialize(), $form.attr("action"));
        // return;
        $.post($form.attr("action"), $form.serialize()).then(function() {
            console.log("Thank you!");
        });
    });

    console.log('hello world')
        // renderMathInElement(document.body, {
        //     // ...options...
        // });
});